const isInputValueEmptyString = (value?: string) => value?.trim() === ""

const isPersonDataEmpty = (personData?: Person) =>
  personData?.firstName && personData?.lastName

const isDepartmentDataEmpty = (departmentData?: Department) =>
  (departmentData?.email === null || departmentData?.email === "") &&
  (departmentData?.phone === null || departmentData?.phone === "")

export const filterFormData = (formData: OnboardingRecord | MerchantRecord) => {
  const filteredFormData = structuredClone(formData)

  if (
    filteredFormData.bankAccount &&
    filteredFormData.bankAccount.type === "NON_SEPA" &&
    isInputValueEmptyString(filteredFormData.bankAccount.iban)
  ) {
    filteredFormData.bankAccount.iban = undefined
  }

  if (isInputValueEmptyString(formData.companySize)) {
    filteredFormData.companySize = undefined
  }

  if (isInputValueEmptyString(formData.companyRevenue)) {
    filteredFormData.companyRevenue = undefined
  }

  if (!isPersonDataEmpty(filteredFormData.dataProtectionOfficer)) {
    filteredFormData.dataProtectionOfficer = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.support)) {
    filteredFormData.support = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.finance)) {
    filteredFormData.finance = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.development)) {
    filteredFormData.development = undefined
  }

  if (isDepartmentDataEmpty(filteredFormData.techSupport)) {
    filteredFormData.techSupport = undefined
  }

  return filteredFormData
}
