import { Box, Typography } from "@mui/material"
import { GridOverlay } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"

const CustomErrorOverlay = () => {
  const { t } = useTranslation("translation")

  return (
    <GridOverlay>
      <Box>
        <Typography variant="h2">
          {t("common.overlays.listFetchErrorOverlay")}
        </Typography>
      </Box>
    </GridOverlay>
  )
}

export default CustomErrorOverlay
