import { atom } from "jotai"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import {
  ApiError,
  get as getClient,
  post as postClient
} from "src/shared/client"
import { userAtom } from "src/shared/stores"

const testContract: MerchantContractRecordUnsigned = {
  contractId: "123456",
  version: "1.0",
  type: "DPA"
}

export const dpaContractIdAtom = atom<string | undefined>(undefined)

export const merchantLatestUnsignedDpaContractDataAtom = atomWithQuery<
  MerchantContractRecordUnsigned,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    queryKey: ["merchantLatestUnsignedDpaContractData"],
    queryFn: () => testContract,
    // queryFn: async () =>
    //   await (
    //     await getClient({
    //       path: `/merchant-service/merchants/${user?.merchantId}/contracts/latest?type=DPA`
    //     })
    //   ).json(),
    enabled: !!user?.merchantId
  }
})

export const merchantAcceptLatestDpaContractAtom = atomWithMutation<
  unknown,
  AcceptLatestDpaContractRequest,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async (contractData) => {
      const response = await postClient({
        path: `/merchant-service/merchants/${user?.merchantId}/contracts`,
        body: contractData
      })

      return response
    }
  }
})

export const merchantPreviewLatestDpaContractAtom = atom<
  (language?: string) => Promise<Blob>
>((get) => async (language) => {
  const { data: user } = get(userAtom)

  const downloadData = await getClient({
    path: `/merchant-service/merchants/${user?.merchantId}/contracts/latest/preview?type=DPA${language ? `&language=${language}` : ""}`,
    headers: { Accept: "*/*" }
  })

  return downloadData.blob()
})

export const onboardingPreviewLatestDpaContractAtom = atom<
  (language?: string) => Promise<Blob>
>((get) => async (language) => {
  const { data: user } = get(userAtom)

  const downloadData = await getClient({
    path: `/merchant-service/onboardings/${user?.sub}/contracts/latest?type=DPA${language ? `&language=${language}` : ""}`,
    headers: { Accept: "*/*" }
  })

  return downloadData.blob()
})
