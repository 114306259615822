import { i18n } from "src/shared/i18n"

export const getCompanySize = (size?: string) => {
  switch (size) {
    default:
      return "~"
    case "SMALL":
      return i18n.t("common.companySizeSmallText")
    case "MIDDLE":
      return i18n.t("common.companySizeMiddleText")
    case "BIG":
      return i18n.t("common.companySizeBigText")
  }
}
