import { useQueryClient } from "@tanstack/react-query"

type ListData<T> = { pagination: ApiPagination; records: T[] }

export const useSetQueriesData = <T>() => {
  const queryClient = useQueryClient()

  const updateListAtomCache = ({
    queryKey,
    updatedData,
    matchFn
  }: {
    queryKey: (string | number)[]
    updatedData: Partial<T>
    matchFn: (record: T) => boolean
  }) => {
    queryClient.setQueriesData<ListData<T> | undefined>(
      { queryKey },
      (oldData) => {
        if (!oldData) return undefined

        const entryIndex = oldData.records.findIndex((record) =>
          matchFn(record)
        )

        if (entryIndex === -1) return oldData

        // Create a new array of records with the updated project data
        const updatedRecords = [...oldData.records]
        updatedRecords[entryIndex] = {
          ...updatedRecords[entryIndex],
          ...updatedData
        }

        return {
          ...oldData,
          records: updatedRecords
        }
      }
    )
  }

  return { updateListAtomCache }
}
