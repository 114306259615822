import { LocalPhone, MailOutline } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/shared/components"

const ContactDataForm = () => {
  const { t } = useTranslation("translation")

  const { control } = useFormContext()

  const contactFormData = {
    isDataProtectionOfficerExternal: {
      inputName: "isDataProtectionOfficerExternal",
      labelName: t(
        "onboardingPage.contactFormData.dataProtectionOfficerCheckboxLabel"
      )
    },
    dataProtectionOfficer: {
      title: {
        inputName: "dataProtectionOfficer.title",
        labelName: t("common.title"),
        rules: { required: t("common.titleRequiredErrorMessage") }
      },
      firstName: {
        inputName: "dataProtectionOfficer.firstName",
        placeholder: t("common.firstNamePlaceholder"),
        labelName: t("common.firstName"),
        rules: {
          required: t("common.firstNameRequiredErrorMessage")
        }
      },
      lastName: {
        inputName: "dataProtectionOfficer.lastName",
        placeholder: t("common.lastNamePlaceholder"),
        labelName: t("common.lastName"),
        rules: { required: t("common.lastNameRequiredErrorMessage") }
      },
      email: {
        inputName: "dataProtectionOfficer.email",
        placeholder: t("common.emailPlaceholder"),
        labelName: t("common.email"),
        rules: { required: t("common.emailRequiredErrorMessage") }
      },
      phone: {
        inputName: "dataProtectionOfficer.phone",
        placeholder: t("common.phonePlaceholder"),
        labelName: t("common.phone")
      }
    },
    support: {
      email: {
        inputName: "support.email",
        placeholder: t("common.emailPlaceholder"),
        labelName: t("common.email")
      },
      phone: {
        inputName: "support.phone",
        placeholder: t("common.phonePlaceholder"),
        labelName: t("common.phone")
      }
    },
    finance: {
      email: {
        inputName: "finance.email",
        placeholder: t("common.emailPlaceholder"),
        labelName: t("common.email")
      },
      phone: {
        inputName: "finance.phone",
        placeholder: t("common.phonePlaceholder"),
        labelName: t("common.phone")
      }
    },
    development: {
      email: {
        inputName: "development.email",
        placeholder: t("common.emailPlaceholder"),
        labelName: t("common.email")
      },
      phone: {
        inputName: "development.phone",
        placeholder: t("common.phonePlaceholder"),
        labelName: t("common.phone")
      }
    },
    techSupport: {
      email: {
        inputName: "techSupport.email",
        placeholder: t("common.emailPlaceholder"),
        labelName: t("common.email")
      },
      phone: {
        inputName: "techSupport.phone",
        placeholder: t("common.phonePlaceholder"),
        labelName: t("common.phone")
      }
    },
    possibleSalutations: [
      {
        text: t("common.defaultSelect"),
        value: ""
      },
      {
        value: "MR",
        text: t("common.maleSalutation")
      },
      {
        value: "MRS",
        text: t("common.femaleSalutation")
      }
    ]
  }

  return (
    <Box data-testid="onboardingContactDataForm">
      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("common.tooltips.dataProtectionOfficer")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={contactFormData.isDataProtectionOfficerExternal.inputName}
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={onChange} checked={value} />}
                      data-testid="dataProtectionCheckbox"
                      label={
                        contactFormData.isDataProtectionOfficerExternal
                          .labelName
                      }
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormSelectGroup
                inputName={
                  contactFormData.dataProtectionOfficer.title.inputName
                }
                labelName={
                  contactFormData.dataProtectionOfficer.title.labelName
                }
                isLabelRequired={true}
                selectItems={contactFormData.possibleSalutations}
                rules={contactFormData.dataProtectionOfficer.title.rules}
              />
            </Grid>

            <Grid item data-testid="dpoFirstNameInput" xs={12} md={5}>
              <FormInputGroup
                placeholder={
                  contactFormData.dataProtectionOfficer.firstName.placeholder
                }
                inputName={
                  contactFormData.dataProtectionOfficer.firstName.inputName
                }
                labelName={
                  contactFormData.dataProtectionOfficer.firstName.labelName
                }
                isLabelRequired={true}
                rules={contactFormData.dataProtectionOfficer.firstName.rules}
              />
            </Grid>
            <Grid item data-testid="dpoLastNameInput" xs={12} md={5}>
              <FormInputGroup
                placeholder={
                  contactFormData.dataProtectionOfficer.lastName.placeholder
                }
                inputName={
                  contactFormData.dataProtectionOfficer.lastName.inputName
                }
                labelName={
                  contactFormData.dataProtectionOfficer.lastName.labelName
                }
                isLabelRequired={true}
                rules={contactFormData.dataProtectionOfficer.lastName.rules}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={
                  contactFormData.dataProtectionOfficer.email.placeholder
                }
                inputName={
                  contactFormData.dataProtectionOfficer.email.inputName
                }
                labelName={
                  contactFormData.dataProtectionOfficer.email.labelName
                }
                isLabelRequired={true}
                rules={contactFormData.dataProtectionOfficer.email.rules}
                startIcon={<MailOutline />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={
                  contactFormData.dataProtectionOfficer.phone.placeholder
                }
                inputName={
                  contactFormData.dataProtectionOfficer.phone.inputName
                }
                labelName={
                  contactFormData.dataProtectionOfficer.phone.labelName
                }
                startIcon={<LocalPhone />}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.contactFormData.support")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.support.email.placeholder}
                inputName={contactFormData.support.email.inputName}
                labelName={contactFormData.support.email.labelName}
                startIcon={<MailOutline />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.support.phone.placeholder}
                inputName={contactFormData.support.phone.inputName}
                labelName={contactFormData.support.phone.labelName}
                startIcon={<LocalPhone />}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.contactFormData.finance")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.finance.email.placeholder}
                inputName={contactFormData.finance.email.inputName}
                labelName={contactFormData.finance.email.labelName}
                startIcon={<MailOutline />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.finance.phone.placeholder}
                inputName={contactFormData.finance.phone.inputName}
                labelName={contactFormData.finance.phone.labelName}
                startIcon={<LocalPhone />}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.contactFormData.development")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.development.email.placeholder}
                inputName={contactFormData.development.email.inputName}
                labelName={contactFormData.development.email.labelName}
                startIcon={<MailOutline />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.development.phone.placeholder}
                inputName={contactFormData.development.phone.inputName}
                labelName={contactFormData.development.phone.labelName}
                startIcon={<LocalPhone />}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.contactFormData.techSupport")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.techSupport.email.placeholder}
                inputName={contactFormData.techSupport.email.inputName}
                labelName={contactFormData.techSupport.email.labelName}
                startIcon={<MailOutline />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={contactFormData.techSupport.phone.placeholder}
                inputName={contactFormData.techSupport.phone.inputName}
                labelName={contactFormData.techSupport.phone.labelName}
                startIcon={<LocalPhone />}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default ContactDataForm
