import { i18n } from "src/shared/i18n"

export const getCompanyRevenue = (revenue?: string) => {
  switch (revenue) {
    default:
      return "~"
    case "SMALL":
      return i18n.t("common.companyRevenueSmallText")
    case "MIDDLE":
      return i18n.t("common.companyRevenueMiddleText")
    case "BIG":
      return i18n.t("common.companyRevenueBigText")
  }
}
