import { Check, ChevronLeft, ChevronRight } from "@mui/icons-material"
import {
  Box,
  Button,
  Container,
  Step,
  StepButton,
  StepLabel,
  Stepper
} from "@mui/material"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import {
  type BaseSyntheticEvent,
  type JSX,
  type ReactNode,
  useEffect,
  useState
} from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  filterFormData,
  useCustomErrorSnackbar,
  useSetQueryData
} from "src/shared/functions"
import { routes } from "src/shared/routing"
import {
  deleteOnboardingDocumentDataAtom,
  onboardingCompanyLanguageAtom,
  onboardingDocumentDataAtom,
  onboardingIsDpaAcceptedAtom,
  onboardingIsDpaAcceptedErrorAtom,
  postOnboardingStatusChangeAtom,
  updateOnboardingDataAtom,
  userAtom
} from "src/shared/stores"

const OnboardingStepper = ({ children }: { children: JSX.Element[] }) => {
  const { t, i18n } = useTranslation("translation")
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { enqueueCustomErrorSnackbar } = useCustomErrorSnackbar()
  const { updateDataAtomCache } = useSetQueryData<OnboardingRecord>()

  const {
    formState: { errors: formErrors },
    handleSubmit,
    reset,
    trigger,
    watch
  } = useFormContext<OnboardingRecord>()

  const isExemptFromTaxFiling = watch("isExemptFromTaxFiling")
  const termsAndConditionsAccepted = watch("termsAndConditionsAccepted")
  const hasFormErrors = Object.keys(formErrors).length > 0

  const [{ data: user }] = useAtom(userAtom)
  const [
    { mutateAsync: updateOnboardingData, isPending: isUpdatingOnboardingData }
  ] = useAtom(updateOnboardingDataAtom)
  const [{ mutateAsync: postNewStatusChange, isPending: isUpdatingStatus }] =
    useAtom(postOnboardingStatusChangeAtom)
  const [{ data: uploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const [{ isPending: isDeletingDocument }] = useAtom(
    deleteOnboardingDocumentDataAtom
  )

  const onboardingCompanyLanguage = useAtomValue(onboardingCompanyLanguageAtom)
  const [isDpaAccepted, setIsDpaAccepted] = useAtom(onboardingIsDpaAcceptedAtom)
  const setIsDpaAcceptedError = useSetAtom(onboardingIsDpaAcceptedErrorAtom)

  const [step, setStep] = useState(0)
  const [activeStepComponent, setActiveStepComponent] = useState(<></>)
  const [completed, setCompleted] = useState<{ [step: number]: boolean }>({})

  const getDisplayName = (Component: ReactNode) => {
    switch (Component) {
      case children[1]:
        return t("onboardingPage.contactFormData.displayName")
      case children[2]:
        return t("onboardingPage.accountFormData.displayName")
      case children[3]:
        return t("onboardingPage.documentsFormData.displayName")
      case children[4]:
        return t("onboardingPage.summaryFormData.displayName")
      default:
        return t("onboardingPage.merchantFormData.displayName")
    }
  }

  const handleUpdateOnboardingData = (
    e: BaseSyntheticEvent,
    goToStep: number
  ) =>
    handleSubmit((updatedFormData) => {
      updateOnboardingData(
        updatedFormData.status === "NOT_YET_STARTED"
          ? {
              updatedOnboardingData: filterFormData({
                ...updatedFormData,
                companyLanguage: onboardingCompanyLanguage,
                status: "IN_PROGRESS"
              }) as OnboardingRecord
            }
          : {
              updatedOnboardingData: filterFormData({
                ...updatedFormData,
                companyLanguage: onboardingCompanyLanguage,
                bankAccount: {
                  ...updatedFormData.bankAccount,
                  accountHolder: null
                }
              }) as OnboardingRecord
            },
        {
          onSuccess: (updatedOnboarding) => {
            updateDataAtomCache({
              queryKey: ["onboardingData"],
              newData: updatedOnboarding
            })

            setCompleted({ ...completed, [step]: true })
            searchParams.set("step", String(goToStep + 1))
            navigate(`/onboarding?${searchParams.toString()}`)
            setIsDpaAccepted(false)
            setIsDpaAcceptedError(false)
          },
          onError: (error) => {
            enqueueCustomErrorSnackbar({ error })
          }
        }
      )
    })(e)

  const handleSubmitOnboardingData = (e: BaseSyntheticEvent) =>
    handleSubmit((updatedFormData) => {
      if (!isDpaAccepted) {
        setIsDpaAcceptedError(true)
        return
      }

      updateOnboardingData(
        {
          updatedOnboardingData: filterFormData({
            ...updatedFormData,
            companyLanguage: onboardingCompanyLanguage,
            bankAccount: { ...updatedFormData.bankAccount, accountHolder: null }
          }) as OnboardingRecord
        },
        {
          onSuccess: (updatedOnboarding) => {
            updateDataAtomCache({
              queryKey: ["onboardingData"],
              newData: updatedOnboarding
            })

            postNewStatusChange(
              {
                status: "READY_FOR_APPROVAL",
                author: `${user?.given_name} ${user?.family_name}`,
                isDPAAccepted: isDpaAccepted
              },
              {
                onSuccess: () => {
                  updateDataAtomCache({
                    queryKey: ["onboardingData"],
                    newData: {
                      ...updatedOnboarding,
                      status: "READY_FOR_APPROVAL"
                    }
                  })

                  setCompleted({ ...completed, [step]: true })
                  navigate(routes.dashboard)

                  enqueueSnackbar({
                    variant: "success",
                    message: t(
                      "onboardingPage.onboardingStepper.submitOnboardingSuccessSnackbar"
                    )
                  })
                },
                onError: (error) => {
                  enqueueCustomErrorSnackbar({ error })
                }
              }
            )
          },
          onError: (error) => {
            enqueueCustomErrorSnackbar({ error })
          }
        }
      )
    })(e)

  const onJumpToStep = (e: BaseSyntheticEvent, jumpToStep: number) => {
    e.preventDefault()
    searchParams.set("step", String(jumpToStep + 1))
    navigate(`/onboarding?${searchParams.toString()}`)
    reset()
    setIsDpaAccepted(false)
    setIsDpaAcceptedError(false)
  }

  const onPrevious = (e: BaseSyntheticEvent) => {
    e.preventDefault()
    searchParams.set("step", String(step))
    navigate(`/onboarding?${searchParams.toString()}`)
    reset()
    setIsDpaAccepted(false)
    setIsDpaAcceptedError(false)
  }

  useEffect(() => {
    trigger() // Revalidates all form fields to translate "required" error messages
  }, [i18n.language, trigger])

  useEffect(() => {
    if (children.length > 0) {
      const activeChild = children.filter((_, index) => index == step)[0]
      setActiveStepComponent(activeChild)
    }
  }, [children, step])

  useEffect(() => {
    if (searchParams.get("step")) {
      const step = Number(searchParams.get("step"))
      setStep(step - 1)

      navigate(`/onboarding?${searchParams.toString()}`)
    } else if (!searchParams.get("step")) {
      setStep(0)
      searchParams.set("step", "1")
      navigate(`/onboarding?${searchParams.toString()}`)
    }
  }, [navigate, searchParams])

  return (
    <Container component="form" data-testid="onboarding-stepper" maxWidth="md">
      <Stepper nonLinear activeStep={step} alternativeLabel>
        {children.map((_, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton
              data-testid={`stepBtn${index}`}
              onClick={(e) => onJumpToStep(e, index)}
            >
              <StepLabel>{getDisplayName(children[index])}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {activeStepComponent}

      <Box className="McpButtons">
        {step !== 0 && (
          <Button
            color="primary"
            variant="outlined"
            disabled={
              isDeletingDocument || isUpdatingOnboardingData || isUpdatingStatus
            }
            disableRipple
            data-testid="stepperBackBtn"
            onClick={onPrevious}
            startIcon={<ChevronLeft />}
          >
            {t("onboardingPage.onboardingStepper.backBtn")}
          </Button>
        )}
        <Button
          disabled={
            isDeletingDocument ||
            isUpdatingOnboardingData ||
            isUpdatingStatus ||
            hasFormErrors ||
            (step === 3 &&
              !isExemptFromTaxFiling &&
              uploadedOnboardingDocuments?.length === 0) ||
            (step === 4 && Object.keys(completed).length < 4) ||
            (step === 4 && !isDpaAccepted) ||
            (step === 4 && !termsAndConditionsAccepted)
          }
          id="nextButton"
          disableRipple
          data-testid={
            step === children.length - 1 ? "stepperSubmitBtn" : "stepperNextBtn"
          }
          variant="contained"
          type="submit"
          endIcon={step === children.length - 1 ? <Check /> : <ChevronRight />}
          color={step === children.length - 1 ? "primary" : "primary"}
          onClick={
            step === children.length - 1
              ? (e) => handleSubmitOnboardingData(e)
              : (e) => handleUpdateOnboardingData(e, step + 1)
          }
        >
          {step === children.length - 1
            ? t("onboardingPage.onboardingStepper.submitBtn")
            : t("onboardingPage.onboardingStepper.nextBtn")}
        </Button>
      </Box>
    </Container>
  )
}
export default OnboardingStepper
