import { CopyAll, ErrorOutline } from "@mui/icons-material"
import {
  AlertTitle,
  Box,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Tooltip as MUITooltip,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { ProjectActivityConfirmationDialog } from "src/features/project"
import { Alert, Chip, Tooltip } from "src/shared/components"
import {
  handleDate,
  handleHomepageString,
  handleTime,
  useCustomErrorSnackbar,
  useSetQueriesData,
  useSetQueryData
} from "src/shared/functions"
import { updateProjectDataAtom } from "src/shared/stores"

const ProjectSummaryView = ({
  projectData
}: {
  projectData: ProjectRecord
}) => {
  const { t } = useTranslation("translation")
  const { enqueueCustomErrorSnackbar } = useCustomErrorSnackbar()

  const [{ mutate: updateProjectData }] = useAtom(updateProjectDataAtom)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { updateDataAtomCache } = useSetQueryData<ProjectRecord>()
  const { updateListAtomCache } = useSetQueriesData<ProjectRecord>()

  useEffect(() => {
    if (projectData) {
      setIsChecked(projectData.activityStatus === "ACTIVE")
    }
  }, [projectData])

  const handleCopy = () => {
    if (projectData?.projectId) {
      navigator.clipboard.writeText(projectData.projectId!)
      setTooltipOpen(true)
      setTimeout(() => {
        setTooltipOpen(false)
      }, 2000)
    }
  }

  const handleProjectActivity = (newActivityStatus: ProjectActivityStatus) => {
    if (projectData) {
      updateProjectData(
        {
          updatedProjectData: {
            ...projectData,
            activityStatus: newActivityStatus
          }
        },
        {
          onSuccess: () => {
            updateDataAtomCache({
              queryKey: ["projectData", projectData.projectId!],
              newData: {
                activityStatus: newActivityStatus
              }
            })

            updateListAtomCache({
              queryKey: ["projectListData"],
              updatedData: {
                activityStatus: newActivityStatus
              },
              matchFn: (record) => record.projectId === projectData.projectId
            })

            setIsDialogOpen(!isDialogOpen)
          },
          onError: (error) => {
            enqueueCustomErrorSnackbar({ error })

            setIsChecked(!isChecked)
          }
        }
      )
    } else return
  }

  const paymentMethods = [
    "Kreditkarte",
    "SEPA-Lastschrift",
    "PayPal",
    "Online Überweisung",
    "Vorkasse",
    "Paysafecard"
  ]

  return (
    <Grid container data-testid="projectSummaryView" rowSpacing={3}>
      {/* PROJECT DETAILS */}
      <Grid item xs={12}>
        <Box className="McpBox">
          <Typography variant="h2" gutterBottom>
            {t("projectDetails.summaryTab.projectDetailsHeadline")}
          </Typography>

          <Container>
            <Grid xs={12} container item alignItems={"flex-start"}>
              <Grid md={12} container item rowSpacing={1}>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Typography variant="subtitle2">
                      {t("projectDetails.summaryTab.createdAt")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Typography>{`${handleDate(projectData.createdAt)} ${t("common.at")} ${handleTime(projectData.createdAt)}`}</Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Typography variant="subtitle2">
                      {t("projectDetails.summaryTab.url")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Typography>
                      {handleHomepageString(projectData.url)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Typography variant="subtitle2">
                      {t("common.ageRating")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Typography>
                      {projectData.ageRating
                        ? t(`common.possibleAgeRating.${projectData.ageRating}`)
                        : "~"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Typography variant="subtitle2">
                      {t("common.category")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Typography>
                      {projectData.category
                        ? t(`common.possibleCategories.${projectData.category}`)
                        : "~"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Box
                    className="McpInfoText"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <ErrorOutline fontSize="small" />
                    {t("projectDetails.summaryTab.projectEditInfoText")}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      {/* PROJECT API */}
      <Grid item xs={12}>
        <Box className="McpBox">
          <Grid item className="McpBoxHeader">
            <Typography variant="h2" gutterBottom justifyContent="spaceBetween">
              {t("projectDetails.summaryTab.projectApiHeadline")}
            </Typography>

            {projectData.status !== "READY_FOR_APPROVAL" && (
              <Grid item xs="auto" ml={"auto"}>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={(e) => {
                          setIsChecked(e.target.checked)
                          setIsDialogOpen(!isDialogOpen)
                        }}
                      />
                    }
                    label={
                      isChecked
                        ? t("projectDetails.summaryTab.activeLabel")
                        : t("projectDetails.summaryTab.inactiveLabel")
                    }
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          <Container>
            <Grid xs={12} container item alignItems={"flex-start"}>
              <Grid md={12} container item rowSpacing={2}>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={2} alignSelf="center">
                    <Typography variant="subtitle2">
                      {t("projectDetails.summaryTab.projectId")}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Paper
                      variant="outlined"
                      sx={{
                        borderColor: "rgba(0,0,0,0.2)",
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <InputBase
                        size="small"
                        disabled
                        sx={{
                          flex: 1,
                          opacity: 1
                        }}
                        value={projectData.projectId}
                      />
                      <MUITooltip
                        title={t("projectDetails.summaryTab.copiedToClipboard")}
                        placement="top"
                        open={tooltipOpen}
                        disableHoverListener
                        disableFocusListener
                        disableTouchListener
                      >
                        <IconButton
                          color="primary"
                          onClick={handleCopy}
                          data-testid="copyToClipboardButton"
                        >
                          <CopyAll />
                        </IconButton>
                      </MUITooltip>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Box
                    className="McpInfoText"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <ErrorOutline fontSize="small" />
                    {t("projectDetails.summaryTab.projectIdInfoText")}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      {/* PROJECT PAYMENT DATA */}
      <Grid item xs={12}>
        <Box className="McpBox">
          <Grid item xs={12}>
            <Typography variant="h2">
              {t("projectDetails.summaryTab.projectPaymentHeadline")}
            </Typography>
          </Grid>

          <Container>
            <Grid xs={12} container item alignItems={"flex-start"}>
              <Grid md={12} container item spacing={2}>
                <Grid xs={12} item mt={2}>
                  <Alert status={projectData.status}>
                    <AlertTitle className="McpAlertText">
                      <Trans
                        i18nKey="projectDetails.summaryTab.paymentAlert"
                        components={{
                          Link: (
                            <a
                              className="McpAlertLink"
                              rel="noreferrer"
                              href="https://example.com"
                              target="_blank"
                            />
                          )
                        }}
                      />
                    </AlertTitle>
                  </Alert>
                </Grid>

                <Grid container item xs={12}>
                  <Box
                    className="McpInfoText"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <ErrorOutline fontSize="small" />
                    {t("projectDetails.summaryTab.paymentMethodInfoText")}
                  </Box>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  sx={{ marginBottom: 2 }}
                >
                  {paymentMethods.map((method) => (
                    <Grid item xs={12} lg={6} xl={4} key={method}>
                      <Grid item xs={12} className="McpBox">
                        <Grid
                          item
                          className="McpBoxHeader"
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body2"
                            gutterBottom
                            justifyContent="spaceBetween"
                          >
                            {method}
                          </Typography>

                          <Tooltip
                            text={t(
                              "projectDetails.summaryTab.paymentChipNotConfigured"
                            )}
                            className="noBorder"
                          >
                            <span>
                              <Chip />
                            </span>
                          </Tooltip>
                        </Grid>
                        <Container>
                          <Typography variant="subtitle2">Something</Typography>
                        </Container>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      {/* ACTIVITY CONFIRMATION DIALOG */}
      <ProjectActivityConfirmationDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        newActivityStatus={isChecked ? "ACTIVE" : "INACTIVE"}
        handleActivityStatusChange={handleProjectActivity}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
    </Grid>
  )
}

export default ProjectSummaryView
