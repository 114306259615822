import { useAtom } from "jotai"
import { lazy, Suspense } from "react"
import { useAuthenticatedPages } from "src/app"
import { LoadingContainer } from "src/shared/components"
import { onboardingDataAtom } from "src/shared/stores"

const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"))

const AuthenticatedAppContainer = () => {
  const [{ isLoading: isLoadingOnboardingData, data: onboardingData }] =
    useAtom(onboardingDataAtom)

  const authenticatedPages = useAuthenticatedPages(onboardingData?.status)

  if (isLoadingOnboardingData) {
    return <LoadingContainer />
  }

  return (
    <Suspense
      fallback={<LoadingContainer data-testid="authApp-suspense-progress" />}
    >
      <AuthenticatedApp authenticatedPages={authenticatedPages} />
    </Suspense>
  )
}

export default AuthenticatedAppContainer
