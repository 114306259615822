import { Add, DeleteForever, LocalPhone, Public } from "@mui/icons-material"
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from "@mui/material"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/shared/components"
import { useI18nCountries } from "src/shared/i18n"

const MerchantDataForm = () => {
  const { t } = useTranslation("translation")
  const { possibleCountries } = useI18nCountries()

  const {
    fields: ceoDeputies,
    append,
    remove
  } = useFieldArray({
    name: "ceoDeputies"
  })

  const { control, watch, setValue, getValues } = useFormContext()
  const isSoleProprietorship = watch("isSoleProprietorship")

  const merchantFormData = {
    isSoleProprietorship: {
      inputName: "isSoleProprietorship",
      labelName: t(
        "onboardingPage.merchantFormData.isSoleProprietorshipCheckboxLabel"
      )
    },
    companyName: {
      inputName: "companyName",
      placeholder: t("common.companyNamePlaceholder"),
      labelName: t("common.companyName"),
      rules: {
        required: t("common.companyNameRequiredErrorMessage")
      }
    },
    legalForm: {
      inputName: "legalForm",
      placeholder: t("common.legalFormPlaceholder"),
      labelName: t("common.legalForm"),
      rules: {
        required: t("common.legalFormRequiredErrorMessage")
      }
    },
    companyNameAddition: {
      inputName: "companyNameAddition",
      placeholder: t("common.companyNameAdditionPlaceholder"),
      labelName: t("common.companyNameAddition")
    },
    phone: {
      inputName: "phone",
      placeholder: t("common.phonePlaceholder"),
      labelName: t("common.phone"),
      rules: {
        required: t("common.phoneRequiredErrorMessage")
      }
    },
    homePage: {
      inputName: "homePage",
      placeholder: t("common.homePagePlaceholder"),
      labelName: t("common.homePage"),
      rules: {
        required: t("common.homePageRequiredErrorMessage")
      }
    },
    address: {
      street: {
        inputName: "address.street",
        placeholder: t("common.streetPlaceholder"),
        labelName: t("common.street"),
        rules: {
          required: t("common.streetRequiredErrorMessage")
        }
      },
      houseNumber: {
        inputName: "address.houseNumber",
        placeholder: t("common.houseNumberPlaceholder"),
        labelName: t("common.houseNumber")
      },
      addressAddition: {
        inputName: "address.addressAddition",
        placeholder: t("common.addressAdditionPlaceholder"),
        labelName: t("common.addressAddition")
      },
      zip: {
        inputName: "address.zip",
        placeholder: t("common.zipPlaceholder"),
        labelName: t("common.zip"),
        rules: {
          required: t("common.zipRequiredErrorMessage")
        }
      },
      city: {
        inputName: "address.city",
        placeholder: t("common.cityPlaceholder"),
        labelName: t("common.city"),
        rules: {
          required: t("common.cityRequiredErrorMessage")
        }
      },
      state: {
        inputName: "address.state",
        placeholder: t("common.statePlaceholder"),
        labelName: t("common.state")
      },
      country: {
        inputName: "address.country",
        labelName: t("common.country"),
        rules: {
          required: t("common.countryRequiredErrorMessage")
        }
      }
    },
    companySize: {
      inputName: "companySize",
      labelName: t("common.companySize"),
      possibleCompanySizes: [
        {
          text: t("common.defaultSelect"),
          value: ""
        },
        {
          text: t("common.companySizeSmallText"),
          value: "SMALL"
        },
        {
          text: t("common.companySizeMiddleText"),
          value: "MIDDLE"
        },
        {
          text: t("common.companySizeBigText"),
          value: "BIG"
        }
      ]
    },
    companyRevenue: {
      inputName: "companyRevenue",
      labelName: t("common.companyRevenue"),
      possibleCompanyRevenue: [
        {
          text: t("common.defaultSelect"),
          value: ""
        },
        {
          text: t("common.companyRevenueSmallText"),
          value: "SMALL"
        },
        {
          text: t("common.companyRevenueMiddleText"),
          value: "MIDDLE"
        },
        {
          text: t("common.companyRevenueBigText"),
          value: "BIG"
        }
      ]
    },
    ceo: {
      title: {
        inputName: "ceo.title",
        labelName: t("common.title"),
        rules: {
          required: t("common.titleRequiredErrorMessage")
        }
      },
      firstName: {
        inputName: "ceo.firstName",
        placeholder: t("common.firstNamePlaceholder"),
        labelName: t("common.firstName"),
        rules: {
          required: t("common.firstNameRequiredErrorMessage")
        }
      },
      lastName: {
        inputName: "ceo.lastName",
        placeholder: t("common.lastNamePlaceholder"),
        labelName: t("common.lastName"),
        rules: {
          required: t("common.lastNameRequiredErrorMessage")
        }
      }
    },
    ceoDeputies: {
      title: {
        labelName: t("common.title"),
        rules: {
          required: t("common.titleRequiredErrorMessage")
        }
      },
      firstName: {
        placeholder: t("common.firstNamePlaceholder"),
        labelName: t("common.firstName"),
        rules: {
          required: t("common.firstNameRequiredErrorMessage")
        }
      },
      lastName: {
        placeholder: t("common.lastNamePlaceholder"),
        labelName: t("common.lastName"),
        rules: {
          required: t("common.lastNameRequiredErrorMessage")
        }
      }
    },
    possibleSalutations: [
      {
        text: t("common.defaultSelect"),
        value: ""
      },
      {
        value: "MR",
        text: t("common.maleSalutation")
      },
      {
        value: "MRS",
        text: t("common.femaleSalutation")
      }
    ]
  }

  return (
    <Grid data-testid="onboardingMerchantDataForm">
      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography
            variant="h2"
            gutterBottom
            data-testid="merchantInfoHeader"
          >
            {t("onboardingPage.merchantFormData.companySubtitle")}
          </Typography>
        </Grid>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={merchantFormData.isSoleProprietorship.inputName}
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            onChange(e.target.checked)
                            if (getValues("isExemptFromTaxFiling")) {
                              setValue("isExemptFromTaxFiling", false)
                            }
                          }}
                          checked={value}
                        />
                      }
                      data-testid="isSoleProprietorshipCheckbox"
                      label={merchantFormData.isSoleProprietorship.labelName}
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            {!isSoleProprietorship && (
              <>
                <Grid item data-testid="companyNameInput" xs={12} md={8}>
                  <FormInputGroup
                    placeholder={merchantFormData.companyName.placeholder}
                    inputName={merchantFormData.companyName.inputName}
                    labelName={merchantFormData.companyName.labelName}
                    isLabelRequired={true}
                    rules={merchantFormData.companyName.rules}
                  />
                </Grid>
                <Grid item xs={12} md={4} data-testid="legalFormInput">
                  <FormInputGroup
                    placeholder={merchantFormData.legalForm.placeholder}
                    inputName={merchantFormData.legalForm.inputName}
                    labelName={merchantFormData.legalForm.labelName}
                    isLabelRequired={true}
                    rules={merchantFormData.legalForm.rules}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  data-testid="companyNameAdditionInput"
                >
                  <FormInputGroup
                    placeholder={
                      merchantFormData.companyNameAddition.placeholder
                    }
                    inputName={merchantFormData.companyNameAddition.inputName}
                    labelName={merchantFormData.companyNameAddition.labelName}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6} data-testid="phoneInput">
              <FormInputGroup
                placeholder={merchantFormData.phone.placeholder}
                inputName={merchantFormData.phone.inputName}
                labelName={merchantFormData.phone.labelName}
                isLabelRequired={true}
                rules={merchantFormData.phone.rules}
                startIcon={<LocalPhone />}
              />
            </Grid>

            <Grid item data-testid="homepageInput" xs={12} md={6}>
              <FormInputGroup
                placeholder={merchantFormData.homePage.placeholder}
                inputName={merchantFormData.homePage.inputName}
                labelName={merchantFormData.homePage.labelName}
                isLabelRequired={true}
                rules={merchantFormData.homePage.rules}
                startIcon={<Public />}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormInputGroup
                placeholder={merchantFormData.address.street.placeholder}
                inputName={merchantFormData.address.street.inputName}
                labelName={merchantFormData.address.street.labelName}
                isLabelRequired={true}
                rules={merchantFormData.address.street.rules}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputGroup
                placeholder={merchantFormData.address.houseNumber.placeholder}
                inputName={merchantFormData.address.houseNumber.inputName}
                labelName={merchantFormData.address.houseNumber.labelName}
              />
            </Grid>

            <Grid item xs={12} md={12} data-testid="addressAdditionInput">
              <FormInputGroup
                placeholder={
                  merchantFormData.address.addressAddition.placeholder
                }
                inputName={merchantFormData.address.addressAddition.inputName}
                labelName={merchantFormData.address.addressAddition.labelName}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputGroup
                placeholder={merchantFormData.address.zip.placeholder}
                inputName={merchantFormData.address.zip.inputName}
                labelName={merchantFormData.address.zip.labelName}
                isLabelRequired={true}
                rules={merchantFormData.address.zip.rules}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormInputGroup
                placeholder={merchantFormData.address.city.placeholder}
                inputName={merchantFormData.address.city.inputName}
                labelName={merchantFormData.address.city.labelName}
                isLabelRequired={true}
                rules={merchantFormData.address.city.rules}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={merchantFormData.address.state.placeholder}
                inputName={merchantFormData.address.state.inputName}
                labelName={merchantFormData.address.state.labelName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectGroup
                inputName={merchantFormData.address.country.inputName}
                labelName={merchantFormData.address.country.labelName}
                isLabelRequired={true}
                selectItems={possibleCountries()}
                rules={merchantFormData.address.country.rules}
                countryFlags={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectGroup
                inputName={merchantFormData.companySize.inputName}
                labelName={merchantFormData.companySize.labelName}
                selectItems={merchantFormData.companySize.possibleCompanySizes}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectGroup
                inputName={merchantFormData.companyRevenue.inputName}
                labelName={merchantFormData.companyRevenue.labelName}
                selectItems={
                  merchantFormData.companyRevenue.possibleCompanyRevenue
                }
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("common.tooltips.ceo")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <FormSelectGroup
                inputName={merchantFormData.ceo.title.inputName}
                labelName={merchantFormData.ceo.title.labelName}
                isLabelRequired={true}
                selectItems={merchantFormData.possibleSalutations}
                rules={merchantFormData.ceo.title.rules}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FormInputGroup
                placeholder={merchantFormData.ceo.firstName.placeholder}
                inputName={merchantFormData.ceo.firstName.inputName}
                labelName={merchantFormData.ceo.firstName.labelName}
                isLabelRequired={true}
                rules={merchantFormData.ceo.firstName.rules}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FormInputGroup
                placeholder={merchantFormData.ceo.lastName.placeholder}
                inputName={merchantFormData.ceo.lastName.inputName}
                labelName={merchantFormData.ceo.lastName.labelName}
                isLabelRequired={true}
                rules={merchantFormData.ceo.lastName.rules}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("common.tooltips.ceoDeputy")}
          </Typography>
        </Grid>
        <Container>
          <Grid container className="McpDeputiesBox">
            {ceoDeputies.map((ceoDeputy, index) => (
              <Grid
                item
                container
                key={ceoDeputy.id}
                className="McpDeputiesContainer"
                data-testid={`deputy_ceo_group_${index}`}
                spacing={2}
              >
                <Grid item xs={12} md={2}>
                  <FormSelectGroup
                    labelName={merchantFormData.ceoDeputies.title.labelName}
                    inputName={`ceoDeputies.${index}.title`}
                    isLabelRequired={true}
                    selectItems={merchantFormData.possibleSalutations}
                    rules={merchantFormData.ceoDeputies.title.rules}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <FormInputGroup
                    placeholder={
                      merchantFormData.ceoDeputies.firstName.placeholder
                    }
                    inputName={`ceoDeputies.${index}.firstName`}
                    labelName={merchantFormData.ceoDeputies.firstName.labelName}
                    isLabelRequired={true}
                    rules={merchantFormData.ceoDeputies.firstName.rules}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <FormInputGroup
                    placeholder={
                      merchantFormData.ceoDeputies.lastName.placeholder
                    }
                    inputName={`ceoDeputies.${index}.lastName`}
                    labelName={merchantFormData.ceoDeputies.lastName.labelName}
                    isLabelRequired={true}
                    rules={merchantFormData.ceoDeputies.lastName.rules}
                  />
                </Grid>

                <Grid item xs={12} className="deleteRow">
                  <Button
                    data-testid={`removeDeputyBtn${index}`}
                    size="small"
                    variant="contained"
                    color="error"
                    startIcon={<DeleteForever />}
                    aria-label="remove"
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    <Box component="span" className="deleteText">
                      {t("common.remove")}
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12} textAlign="center">
              <Button
                data-testid="addDeputyBtn"
                size="small"
                color="primary"
                aria-label="add"
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  append(
                    {
                      title: "MR",
                      firstName: "",
                      lastName: "",
                      email: null,
                      phone: null
                    },
                    { shouldFocus: false }
                  )
                }}
              >
                {t("onboardingPage.merchantFormData.addDeputy")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Grid>
  )
}

export default MerchantDataForm
