import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"

export const useListConfig = ({
  listData
}: {
  listData?: UnionListData<RecordWithStatus | RecordWithoutStatus>
}) => {
  const navigate = useNavigate()

  const handleSetStatusFilter = useCallback(
    ({
      status,
      id,
      path,
      searchParams
    }: {
      searchParams: URLSearchParams
      status: UnionStatus
      path: string
      id?: string
    }) => {
      const oldParams = searchParams
      const newParams = new URLSearchParams()
      newParams.set("status", status)

      for (const [key, value] of oldParams.entries()) {
        if (key !== "status") {
          newParams.set(key, value)
        }
      }

      const newPath = `/${path}${id ? `/${id}` : ""}?${newParams.toString()}`
      navigate(newPath)
    },
    [navigate]
  )

  const handleResetStatusFilter = useCallback(
    ({
      id,
      path,
      searchParams
    }: {
      id?: string
      path: string
      searchParams: URLSearchParams
    }) => {
      searchParams.delete("status")

      const newPath = `/${path}${id ? `/${id}` : ""}${searchParams ? `?${searchParams.toString()}` : ""}`
      navigate(newPath)
    },
    [navigate]
  )

  const hasStatus = (
    record: RecordWithStatus | RecordWithoutStatus
  ): record is RecordWithStatus =>
    "status" in record && typeof record.status === "string"

  const getRowCount = useCallback(
    (searchParams?: URLSearchParams) => {
      if (!searchParams || !searchParams.get("status")) {
        return listData?.pagination.totalRecords
      }
      return (
        listData?.records.filter(
          (row) => hasStatus(row) && row.status === searchParams.get("status")
        ).length || 0
      )
    },
    [listData?.pagination.totalRecords, listData?.records]
  )

  const pagination = useMemo(() => listData?.pagination, [listData?.pagination])

  return {
    handleSetStatusFilter,
    handleResetStatusFilter,
    getRowCount,
    pagination
  }
}
