import { GridPaginationModel } from "@mui/x-data-grid"
import { atom } from "jotai"
import { atomWithReset } from "jotai/utils"
import { atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

const testDpaRecords: PaginatedMerchantContractListData = {
  records: [
    {
      merchantContractId: "4509f16e-6327-4dae-b3a8-01da4ba75fbg",
      contractId: "4509f16e-6327-4dae-b3a8-01da4ba75fbf",
      language: "DE",
      version: "1.3",
      type: "DPA",
      ip: "127.0.0.1",
      signDate: "2025-01-22T09:11:27.186521Z"
    },
    {
      merchantContractId: "4509f16e-6327-4dae-b3a8-01da4ba75fbe",
      contractId: "4509f16e-6327-4dae-b3a8-01da4ba75fbd",
      language: "EN",
      version: "1.2",
      type: "DPA",
      ip: "127.0.0.1",
      signDate: "2025-01-21T09:11:27.186521Z"
    },
    {
      merchantContractId: "4509f16e-6327-4dae-b3a8-01da4ba75fbz",
      contractId: "4509f16e-6327-4dae-b3a8-01da4ba75fbd",
      language: "EN",
      version: "1.0",
      type: "DPA",
      ip: "127.0.0.127.0.0.1",
      signDate: "2024-01-21T09:11:27.186521Z"
    }
  ],
  pagination: {
    page: 0,
    size: 10,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 3
  }
}

export const merchantDpaContractListPaginationAtom =
  atomWithReset<GridPaginationModel>({
    pageSize: 10,
    page: 0
  })

export const merchantDpaContractListDataAtom = atomWithQuery<
  PaginatedMerchantContractListData,
  ApiError
>((get) => {
  const { pageSize, page } = get(merchantDpaContractListPaginationAtom)
  const { data: user } = get(userAtom)

  return {
    queryKey: ["merchantDpaContractListData", pageSize, page],
    queryFn: () => testDpaRecords,
    // queryFn: async () => {
    //   const response = await (
    //     await getClient({
    //       path: `/merchant-service/merchants/${user?.merchantId}/contracts?size=${pageSize}&page=${page}&type=DPA`
    //     })
    //   ).json()

    //   return {
    //     ...response,
    //     pagination: {
    //       ...response.pagination,
    //       pageSize
    //     }
    //   }
    // },
    enabled: !!user?.merchantId
  }
})

export const downloadMerchantDpaContractAtom = atom<
  (contractId: string, language?: string) => Promise<Blob>
>((get) => async (contractId, language) => {
  const { data: user } = get(userAtom)

  const downloadData = await getClient({
    path: `/merchant-service/merchants/${user?.merchantId}/contracts/${contractId}${language ? `?language=${language}` : ""}`,
    headers: { Accept: "*/*" }
  })

  return downloadData.blob()
})
