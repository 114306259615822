import { Box } from "@mui/material"
import { Chip } from "src/shared/components"
import { useListConfig } from "src/shared/functions"

type ListFilterViewProps = {
  searchParams: URLSearchParams
  id?: string
  path: ChipLabelType
  statuses: UnionStatus[]
  listData?: UnionListData<RecordWithStatus>
}

const ListFilterView = ({
  searchParams,
  id,
  path,
  statuses,
  listData
}: ListFilterViewProps) => {
  const { handleSetStatusFilter, handleResetStatusFilter } = useListConfig({
    listData
  })

  return (
    <Box className="McpStatusFilter">
      <Chip
        key="all"
        status="ALL"
        pathname={path}
        variant={!searchParams.get("status") ? "filled" : "outlined"}
        resetFilterStatus={() =>
          handleResetStatusFilter({ id, path, searchParams })
        }
      />
      {statuses.map((status) => (
        <Chip
          key={status}
          status={status}
          pathname={path}
          variant={
            searchParams.get("status") === status ? "filled" : "outlined"
          }
          setFilterStatus={() =>
            handleSetStatusFilter({
              status,
              id,
              path,
              searchParams
            })
          }
        />
      ))}
    </Box>
  )
}

export default ListFilterView
