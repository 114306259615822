import { useSetAtom } from "jotai"
import { useCallback } from "react"
import { isDrawerOpenAtom } from "src/shared/stores"

export const useDrawerFunctions = () => {
  const setIsDrawerOpen = useSetAtom(isDrawerOpenAtom)

  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true)
  }, [setIsDrawerOpen])

  const handleDrawerClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [setIsDrawerOpen])

  return { handleDrawerOpen, handleDrawerClose }
}
