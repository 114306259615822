import { fetchAuthSession } from "@aws-amplify/auth"
import { type GridPaginationModel } from "@mui/x-data-grid"
import { keepPreviousData } from "@tanstack/react-query"
import { atomWithReset } from "jotai/utils"
import { atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient } from "src/shared/client"
import { onboardingDataAtom } from "src/shared/stores"

const MAX_RETRIES = 3

export const projectListPaginationAtom = atomWithReset<GridPaginationModel>({
  pageSize: 10,
  page: 0
})

export const projectListDataAtom = atomWithQuery<
  PaginatedProjectListData,
  ApiError
>((get) => {
  const { pageSize, page } = get(projectListPaginationAtom)
  const { data: onboardingData } = get(onboardingDataAtom)
  const isMerchantApproved = onboardingData?.status === "APPROVED"

  const getProjectListData = async (
    retryCount: number = 0
  ): Promise<PaginatedProjectListData> => {
    try {
      const response = await (
        await getClient({
          path: `/project-service/projects?size=${pageSize}&page=${page}`
        })
      ).json()

      return {
        ...response,
        pagination: {
          ...response.pagination,
          pageSize
        }
      }
    } catch (error) {
      if (error instanceof ApiError) {
        const response: ServiceError = await error.errorResponse.clone().json()

        if (response.code === "UNAUTHORIZED" && retryCount < MAX_RETRIES) {
          await fetchAuthSession({ forceRefresh: true })

          return getProjectListData(retryCount + 1)
        } else {
          throw error
        }
      } else {
        throw error
      }
    }
  }

  return {
    queryKey: ["projectListData", pageSize, page],
    queryFn: async () => getProjectListData(),
    placeholderData: keepPreviousData,
    enabled: !!isMerchantApproved
  }
})
