import { InfoOutlined, PublishedWithChanges } from "@mui/icons-material"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { useAtom, useAtomValue } from "jotai"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ApiError } from "src/shared/client"
import {
  normalizeWhitespace,
  useCustomErrorSnackbar,
  useSetQueriesData,
  useSetQueryData
} from "src/shared/functions"
import {
  postProjectStatusChangeAtom,
  projectIdAtom,
  projectStatusChangeListAtom
} from "src/shared/stores"

import ProjectStatusChangeConfirmationDialog from "./ProjectStatusChangeConfirmationDialog"

const ProjectChangeStatusBox = () => {
  const { t } = useTranslation("translation")
  const { enqueueCustomErrorSnackbar } = useCustomErrorSnackbar()

  const projectId = useAtomValue(projectIdAtom)
  const [{ mutate: postProjectStatusChange }] = useAtom(
    postProjectStatusChangeAtom
  )
  const [{ refetch: refetchComments }] = useAtom(projectStatusChangeListAtom)

  const [comment, setComment] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const { updateDataAtomCache } = useSetQueryData<ProjectRecord>()
  const { updateListAtomCache } = useSetQueriesData<ProjectRecord>()

  const handleStatusChange = async (status: ProjectStatus) => {
    postProjectStatusChange(
      {
        status,
        comment: comment
      },
      {
        onSuccess: async () => {
          try {
            await refetchComments()

            updateDataAtomCache({
              queryKey: ["projectData", projectId!],
              newData: { status }
            })

            updateListAtomCache({
              queryKey: ["projectListData"],
              updatedData: { status },
              matchFn: (record) => record.projectId === projectId
            })

            setComment(null)
            setIsDialogOpen(!isDialogOpen)
          } catch (error) {
            if (error instanceof ApiError) {
              enqueueCustomErrorSnackbar({ error })
            }
          }
        },
        onError: (error) => {
          enqueueCustomErrorSnackbar({ error })

          setIsDialogOpen(!isDialogOpen)
        }
      }
    )
  }

  return (
    <>
      <Box>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          data-testid="commentInputButtonGroup"
        >
          <Typography sx={{ mb: 1 }}>
            {t("common.commentsTab.commentInputTitle")}
          </Typography>

          <Typography>
            {t("common.commentsTab.charCountText", {
              charCount: comment ? comment.length : 0
            })}
          </Typography>
        </Grid>
        <Grid xs={12} item rowSpacing={2} container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="comment"
              placeholder={t("projectDetails.commentsTab.commentPlaceholder")}
              type="text"
              data-testid="projectCommentField"
              id="comment"
              multiline
              rows={4}
              value={comment ?? ""}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className="McpInfoText">
            <InfoOutlined fontSize="small" />
            <Typography variant="caption">
              {t("projectDetails.commentsTab.commentInfoText")}
            </Typography>
          </Grid>
          <Grid item className="McpButtons">
            <Button
              className="McpButton"
              startIcon={<PublishedWithChanges />}
              color="warning"
              data-testid="proj-return-button"
              variant="contained"
              disabled={
                comment === null ||
                (comment !== null && normalizeWhitespace(comment).length < 1)
              }
              onClick={() => {
                setIsDialogOpen(!isDialogOpen)
              }}
            >
              {t("common.resubmitBtn")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ProjectStatusChangeConfirmationDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        newProjectStatus="READY_FOR_APPROVAL"
        comment={comment}
        handleStatusChange={handleStatusChange}
      />
    </>
  )
}

export default ProjectChangeStatusBox
