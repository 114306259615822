import { useQueryClient } from "@tanstack/react-query"

const mergeData = <T>(
  oldData: T | undefined,
  newData: Partial<T>
): T | undefined => {
  if (!oldData) return undefined
  return { ...oldData, ...newData }
}

export const useSetQueryData = <T>() => {
  const queryClient = useQueryClient()

  const updateDataAtomCache = ({
    queryKey,
    newData
  }: {
    queryKey: (string | number)[]
    newData: Partial<T>
  }) => {
    queryClient.setQueryData(queryKey, (oldData: T | undefined) =>
      mergeData(oldData, newData)
    )
  }

  return { updateDataAtomCache }
}
