import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { ApiError } from "src/shared/client"

type UseCustomErrorSnackbarProps = {
  error: ApiError | AxiosError<ServiceError>
  message?: string
}

const isAxiosError = (error: unknown): error is AxiosError<ServiceError> =>
  (error as AxiosError).isAxiosError !== undefined

export const useCustomErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation("translation")

  const enqueueCustomErrorSnackbar = async ({
    error,
    message
  }: UseCustomErrorSnackbarProps) => {
    let response: ServiceError | undefined

    if (error instanceof ApiError) {
      response = await error.errorResponse.json()
    } else if (isAxiosError(error)) {
      response = error.response?.data
    }

    if (response) {
      enqueueSnackbar({
        variant: "detailedSnackbar",
        message: t(response.code, { keyPrefix: "errorCodes" }),
        details: response.message,
        autoHideDuration: null
      })
    } else {
      enqueueSnackbar({
        variant: "error",
        message,
        autoHideDuration: null
      })
    }
  }

  return { enqueueCustomErrorSnackbar }
}
