import { useAtom } from "jotai"
import { lazy, Suspense, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { LoadingContainer } from "src/shared/components"
import { routes } from "src/shared/routing"
import { userAtom } from "src/shared/stores"

const AccountPage = lazy(() => import("./AccountPage"))

const AccountPageContainer = () => {
  const navigate = useNavigate()

  const [{ isLoading: isLoadingUser, data: user }] = useAtom(userAtom)

  useEffect(() => {
    if (!isLoadingUser && !user?.merchantId) {
      navigate(routes.dashboard)
    }
  }, [isLoadingUser, navigate, user?.merchantId])

  if (!user) {
    return <LoadingContainer />
  }

  return (
    <Suspense
      fallback={<LoadingContainer data-testid="account-suspense-progress" />}
    >
      <AccountPage merchantId={user.merchantId ?? ""} />
    </Suspense>
  )
}

export default AccountPageContainer
