import { Check, Close, Edit } from "@mui/icons-material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
  AlertTitle,
  Box,
  Grid,
  IconButton,
  Tab,
  TextField,
  Typography
} from "@mui/material"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import { type SyntheticEvent, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  ProjectChangeStatusBox,
  ProjectCommentListView,
  ProjectSummaryView
} from "src/features/project"
import {
  Alert,
  Chip,
  ErrorWarning,
  LoadingContainer,
  Tooltip
} from "src/shared/components"
import {
  useCustomErrorSnackbar,
  useDrawerFunctions,
  useSetQueriesData,
  useSetQueryData
} from "src/shared/functions"
import {
  isDrawerOpenAtom,
  projectCommentsCountAtom,
  projectDataAtom,
  projectIdAtom,
  projectStatusChangeListAtom,
  updateProjectDataAtom
} from "src/shared/stores"
import { DetailsDrawer } from "src/widgets/shared"

const ProjectDetails = () => {
  const { t } = useTranslation("translation")
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueCustomErrorSnackbar } = useCustomErrorSnackbar()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [searchParams] = useSearchParams()

  const isDrawerOpen = useAtomValue(isDrawerOpenAtom)
  const setProjectId = useSetAtom(projectIdAtom)
  const [
    {
      data: projectData,
      error: projectDataFetchingError,
      isFetching: isFetchingProjectData,
      isPending: isLoadingProjectData,
      isError: isErrorFetchingProjectData
    }
  ] = useAtom(projectDataAtom)
  const [{ mutate: updateProjectData, isPending: isUpdatingProjectData }] =
    useAtom(updateProjectDataAtom)
  const [
    {
      data: projectStatusAndCommentData,
      error: projectStatusAndCommentDataFetchingError,
      isError: isErrorFetchingProjectStatusAndCommentData
    }
  ] = useAtom(projectStatusChangeListAtom)
  const [projectCommentsCount, setProjectCommentsCount] = useAtom(
    projectCommentsCountAtom
  )

  const [newProjectName, setNewProjectName] = useState<string | null>(null)
  const [isEditingProjectName, setIsEditingProjectName] = useState(false)

  const { handleDrawerClose } = useDrawerFunctions()
  const { updateDataAtomCache } = useSetQueryData<ProjectRecord>()
  const { updateListAtomCache } = useSetQueriesData<ProjectListRecord>()

  const projectStatus = projectData?.status as ProjectStatus

  const handleUpdateProjectName = (
    projectData: ProjectRecord,
    newProjectName: string | null
  ) => {
    if (newProjectName !== null && newProjectName !== "") {
      updateProjectData(
        {
          updatedProjectData: { ...projectData, name: newProjectName }
        },
        {
          onSuccess: (updatedProject) => {
            updateDataAtomCache({
              queryKey: ["projectData", projectData.projectId!],
              newData: {
                name: newProjectName
              }
            })

            updateListAtomCache({
              queryKey: ["projectListData"],
              updatedData: updatedProject,
              matchFn: (record) => record.projectId === projectData.projectId
            })

            setIsEditingProjectName(false)

            enqueueSnackbar({
              variant: "success",
              message: t(
                "projectDetails.summaryTab.projectDataUpdateSuccessSnackbar"
              )
            })
          },
          onError: (error) => {
            setIsEditingProjectName(false)

            enqueueCustomErrorSnackbar({ error })
          }
        }
      )
    } else {
      setIsEditingProjectName(false)
    }
  }

  const handleTabChange = (_event: SyntheticEvent, newTabPage: string) => {
    searchParams.set("tab", newTabPage)
    navigate(`/projects/${projectId}?${searchParams.toString()}`)
  }

  const handleCloseButtonClick = useCallback(() => {
    setProjectId(undefined)
    searchParams.delete("tab")

    handleDrawerClose()
    navigate("/projects")
  }, [handleDrawerClose, navigate, searchParams, setProjectId])

  useEffect(() => {
    if (projectId && !searchParams.get("tab")) {
      searchParams.set("tab", "1")

      navigate(`/projects/${projectId}?${searchParams.toString()}`)
    }
  }, [projectId, navigate, searchParams])

  useEffect(() => {
    if (projectStatusAndCommentData) {
      setProjectCommentsCount(
        projectStatusAndCommentData.changelog.filter(
          (commentRecord) => commentRecord.comment
        ).length
      )
    }
  }, [setProjectCommentsCount, projectStatusAndCommentData])

  // Enable escape key to cancel editing project name and close the drawer
  useEffect(() => {
    if (!isDrawerOpen) return

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        const activeElement = document.activeElement

        if (!activeElement?.classList.contains("MuiInputBase-input")) {
          handleCloseButtonClick()
        } else {
          setIsEditingProjectName(false)
          if (newProjectName) setNewProjectName(null)
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleCloseButtonClick, newProjectName, isDrawerOpen])

  useEffect(() => {
    if (projectDataFetchingError) {
      enqueueCustomErrorSnackbar({ error: projectDataFetchingError })
    }

    if (projectStatusAndCommentDataFetchingError) {
      enqueueCustomErrorSnackbar({
        error: projectStatusAndCommentDataFetchingError
      })
    }
  }, [
    enqueueCustomErrorSnackbar,
    projectDataFetchingError,
    projectStatusAndCommentDataFetchingError
  ])

  if (isErrorFetchingProjectData) {
    return (
      <ErrorWarning
        testId="projectDetailsError"
        message="projectDetails.projectDataFetchFailureAlert"
      />
    )
  }

  if (isLoadingProjectData || !projectData) {
    return <LoadingContainer />
  }

  return (
    <DetailsDrawer testId="projectDetails">
      <Grid xs={12} item container className="McpDrawerFixed">
        <Grid
          xs={12}
          item
          container
          alignItems="center"
          justifyContent="space-between"
          className="McpDrawerFixedSubheadline"
        >
          <Grid xs="auto" item>
            <Tooltip text={t("common.projectStatus")} className="noBorder">
              <span>
                <Chip status={projectData.status} pathname="projects" />
              </span>
            </Tooltip>
          </Grid>
          <Grid
            xs="auto"
            item
            container
            spacing={1}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Tooltip text={`${t("common.close")}`}>
                <IconButton
                  onClick={handleCloseButtonClick}
                  className="projectDrawerCloseButton"
                  data-testid="projectDrawerCloseButton"
                >
                  <Close fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} item container className="McpDrawerFixedHeadline">
          <Grid xs={12} item container gap={1}>
            {isEditingProjectName ? (
              <>
                <Grid
                  container
                  item
                  className="McpQuickEditInput"
                  columnSpacing={1}
                >
                  <TextField
                    disabled={isUpdatingProjectData || isFetchingProjectData}
                    defaultValue={projectData.name}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setNewProjectName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        handleUpdateProjectName(projectData, newProjectName)
                      }
                    }}
                    inputProps={{
                      autoFocus: true
                    }}
                  />
                  <Grid container item columnSpacing={1}>
                    <Grid item>
                      <Tooltip text={t("common.saveBtn")}>
                        <IconButton
                          disabled={
                            isUpdatingProjectData || isFetchingProjectData
                          }
                          onClick={() =>
                            handleUpdateProjectName(projectData, newProjectName)
                          }
                        >
                          <Check />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip text={t("common.cancelBtn")}>
                        <IconButton
                          disabled={
                            isUpdatingProjectData || isFetchingProjectData
                          }
                          onClick={() => {
                            setIsEditingProjectName(false)
                            if (newProjectName) setNewProjectName(null)
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {/* EDIT ICON EXCLUDE IN STATUS READY_FOR_APPROVAL */}
                {projectData.status !== "READY_FOR_APPROVAL" ? (
                  <>
                    <Tooltip
                      text={t("common.editText", {
                        projectName: projectData.name
                      })}
                      className="noBorder fullWidth"
                    >
                      <div>
                        <Typography
                          variant="h2"
                          className="McpQuickEditHeadline"
                          onClick={() => setIsEditingProjectName(true)} // Korrektur hier
                        >
                          {projectData.name}
                          <IconButton
                            onClick={() => setIsEditingProjectName(true)}
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </Typography>
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <Typography variant="h2">{projectData.name}</Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>

        {/* ALERT BOX ONLY IN WAITING_FOR_MERCHANT */}
        {projectData.status === "WAITING_FOR_MERCHANT" && (
          <Grid xs={12} item mb={2}>
            <Alert status={projectData.status}>
              <AlertTitle>
                {t(`projectDetails.headlineAlert.${projectStatus}`)}
              </AlertTitle>
            </Alert>
          </Grid>
        )}
      </Grid>

      <Grid xs={12} item className="McpTabs">
        <TabContext value={searchParams.get("tab") ?? "1"}>
          <Box className="McpTabsContext">
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab label={t("projectDetails.summaryTab.tabLabel")} value="1" />
              <Tab
                label={`${t("common.commentsTab.tabLabel")} (${projectCommentsCount})`}
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1" className="McpSummaryView">
            {<ProjectSummaryView projectData={projectData} />}
          </TabPanel>
          <TabPanel value="2" className="McpSummaryView">
            {isErrorFetchingProjectStatusAndCommentData ||
            !projectStatusAndCommentData ? (
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                data-testid="projectDetailsError"
              >
                {t("projectDetails.projectDataFetchFailureAlert")}
              </Box>
            ) : (
              <>
                <ProjectCommentListView
                  projectData={projectData}
                  projectStatusAndCommentData={projectStatusAndCommentData}
                />

                {projectData.status === "WAITING_FOR_MERCHANT" && (
                  <ProjectChangeStatusBox />
                )}
              </>
            )}
          </TabPanel>
        </TabContext>
      </Grid>
    </DetailsDrawer>
  )
}

export default ProjectDetails
