import {
  DashboardCustomize,
  DataUsage,
  FolderCopy,
  Groups,
  Person
} from "@mui/icons-material"
import { AccountPageContainer } from "src/pages/account"
import { OnboardingPage } from "src/pages/onboarding"
import { OverviewPage } from "src/pages/overview"
import { ProjectsPage } from "src/pages/projects"
import { routes } from "src/shared/routing"

const useAuthenticatedPages = (status?: OnboardingStatus) => {
  const includePage = (condition: boolean, page: NavItem) =>
    condition ? page : null

  const pages: (NavItem | null)[] = [
    {
      path: routes.dashboard,
      label: "dashboard",
      visible: true,
      icon: <DataUsage />,
      page: <OverviewPage />
    },
    includePage(
      status === "IN_PROGRESS" ||
        status === "WAITING_FOR_MERCHANT" ||
        status === "NOT_YET_STARTED",
      {
        path: routes.onboarding,
        label: "onboarding",
        visible: true,
        icon: <Groups />,
        page: <OnboardingPage />
      }
    ),
    includePage(status === "APPROVED", {
      path: routes.account.index,
      label: "account",
      visible: true,
      icon: <Person />,
      page: <AccountPageContainer />,
      nestedRoutes: [
        {
          path: routes.account.nestedRoutes.accountData,
          page: <AccountPageContainer />
        },
        {
          path: routes.account.nestedRoutes.company,
          page: <AccountPageContainer />
        },
        {
          path: routes.account.nestedRoutes.contact,
          page: <AccountPageContainer />
        },
        {
          path: routes.account.nestedRoutes.bankAccount,
          page: <AccountPageContainer />
        },
        {
          path: routes.account.nestedRoutes.legal,
          page: <AccountPageContainer />
        },
        {
          path: routes.account.nestedRoutes.dpa,
          page: <AccountPageContainer />
        }
      ],
      defaultRoute: routes.account.nestedRoutes.accountData
    }),
    {
      path: "/projects",
      label: "projects",
      visible: true,
      icon: <FolderCopy />,
      page: <ProjectsPage />,
      nestedRoutes: [
        {
          path: routes.projects.nestedRoutes.projectDetail,
          page: <ProjectsPage />
        }
      ]
    },
    {
      path: "/dummy",
      label: "settings",
      visible: true,
      icon: <DashboardCustomize />,
      page: <div>Dummy Hauptseite</div>,
      nestedRoutes: [
        {
          path: "/dummy/dummy1",
          label: "dummyLabel1",
          visible: true,
          page: <div>Dummy Unterseite 1</div>
        },
        {
          path: "/dummy/dummy2",
          label: "dummyLabel2",
          visible: true,
          page: <div>Dummy Unterseite 2</div>
        }
      ],
      accordionLabel: "settings"
    }
  ]

  return pages.filter(Boolean) as NavItem[]
}

export default useAuthenticatedPages
