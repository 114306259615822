import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

const ErrorWarning = ({
  testId,
  message
}: {
  testId?: string
  message: string
}) => {
  const { t } = useTranslation("translation")

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      data-testid={testId ?? ""}
      height="100px"
    >
      {t(message)}
    </Box>
  )
}

export default ErrorWarning
