import { Box } from "@mui/material"
import type {
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridRenderCellParams,
  GridValidRowModel
} from "@mui/x-data-grid"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { PaginatedDataTable } from "src/features/paginatedDataTable"
import { OnboardingStateNoRowsOverlay } from "src/features/project"
import { Chip, ListFilterView } from "src/shared/components"
import {
  handleDate,
  useCustomErrorSnackbar,
  useDrawerFunctions,
  useListConfig
} from "src/shared/functions"
import {
  isDrawerOpenAtom,
  onboardingDataAtom,
  projectIdAtom,
  projectListDataAtom,
  projectListPaginationAtom
} from "src/shared/stores"

const ProjectList = () => {
  const { t } = useTranslation("translation")
  const { enqueueCustomErrorSnackbar } = useCustomErrorSnackbar()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [searchParams] = useSearchParams()

  const isDrawerOpen = useAtomValue(isDrawerOpenAtom)
  const [{ data: onboardingData }] = useAtom(onboardingDataAtom)
  const setProjectId = useSetAtom(projectIdAtom)
  const setPagination = useSetAtom(projectListPaginationAtom)
  const [
    {
      data: projectListData,
      error: projectListDataFetchingError,
      isFetching: isFetchingProjectListData,
      isError: isErrorFetchingProjectListData
    }
  ] = useAtom(projectListDataAtom)

  const { getRowCount, pagination } = useListConfig({
    listData: projectListData
  })
  const { handleDrawerClose, handleDrawerOpen } = useDrawerFunctions()

  const projectInfo: ListInfo = {
    statuses: [
      "READY_FOR_APPROVAL",
      "WAITING_FOR_MERCHANT",
      "APPROVED",
      "DECLINED"
    ],
    path: "projects"
  }

  const [projectFilterModel, setProjectFilterModel] = useState<GridFilterModel>(
    {
      items: []
    }
  )

  const generateRowData = useCallback(
    (projectRecords?: ProjectListRecord[]) =>
      projectRecords?.map((entry: ProjectListRecord) => ({
        id: entry.projectId,
        projectId: entry.projectId,
        projectActivityStatus: entry.activityStatus,
        projectName: entry.name,
        createdAt: handleDate(entry.createdAt),
        projectWebsite: entry.url,
        projectStatus: entry.status
      })),
    []
  )

  const rowData = useMemo(
    () => generateRowData(projectListData?.records),
    [generateRowData, projectListData?.records]
  )

  const columnDefinition: GridColDef[] = useMemo(
    () => [
      {
        field: "projectId",
        headerName: t("projectsList.idColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 2
      },
      {
        field: "projectActivityStatus",
        headerName: t("projectsList.activityStatusColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 1,
        renderCell: ({
          value
        }: GridRenderCellParams<GridValidRowModel, ProjectActivityStatus>) => (
          <Chip status={value} pathname={"projects"} />
        )
      },
      {
        field: "projectName",
        headerName: t("projectsList.projectNameColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 2
      },
      {
        field: "projectWebsite",
        headerName: t("projectsList.projectWebsiteColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 2
      },
      {
        field: "createdAt",
        headerName: t("projectsList.createdAtColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 1
      },
      {
        field: "projectStatus",
        headerName: t("projectsList.statusColumn"),
        headerClassName: "gridHeaderStyle",
        flex: 1,
        renderCell: ({
          value
        }: GridRenderCellParams<GridValidRowModel, ProjectStatus>) => (
          <Chip status={value} pathname={"projects"} />
        )
      }
    ],
    [t]
  )

  const rowCount = getRowCount(searchParams)

  const rowClickHandler: GridEventListener<"rowClick"> = useCallback(
    (event) => {
      if (!isDrawerOpen) {
        setProjectId(event.id as string)
        searchParams.set("tab", "1")

        const newUrl = `/projects/${event.id as string}?${searchParams.toString()}`

        handleDrawerOpen()
        navigate(newUrl)
      } else if (isDrawerOpen && event.id === projectId) {
        setProjectId(undefined)
        searchParams.delete("tab")

        handleDrawerClose()
        navigate("/projects")
      } else {
        setProjectId(event.id as string)

        const newUrl = `/projects/${event.id as string}?${searchParams.toString()}`
        navigate(newUrl)
      }
    },
    [
      handleDrawerClose,
      handleDrawerOpen,
      isDrawerOpen,
      navigate,
      projectId,
      searchParams,
      setProjectId
    ]
  )

  const customOverlay = {
    customOverlay: () => <OnboardingStateNoRowsOverlay />,
    overlayCondition: onboardingData?.status !== "APPROVED"
  }

  useEffect(() => {
    if (searchParams.get("status")) {
      setProjectFilterModel({
        items: [
          {
            field: "projectStatus",
            operator: "equals",
            value: searchParams.get("status")
          }
        ]
      })
    } else setProjectFilterModel({ items: [] })
  }, [searchParams])

  useEffect(() => {
    if (projectListDataFetchingError) {
      enqueueCustomErrorSnackbar({ error: projectListDataFetchingError })
    }
  }, [enqueueCustomErrorSnackbar, projectListDataFetchingError])

  return (
    <Box>
      {/* FILTER BUTTON */}
      <ListFilterView
        searchParams={searchParams}
        id={projectId}
        path={projectInfo.path}
        statuses={projectInfo.statuses}
        listData={projectListData}
      />

      {/* RECORDS LIST */}
      {onboardingData?.status !== "APPROVED" ? (
        <PaginatedDataTable
          rowData={[]}
          columnDefinition={columnDefinition}
          rowCount={0}
          pagination={pagination}
          setPagination={setPagination}
          isLoading={isFetchingProjectListData}
          isErrorFetchingData={isErrorFetchingProjectListData}
          customOverlay={customOverlay}
        />
      ) : (
        <PaginatedDataTable
          id={projectId}
          filterModel={projectFilterModel}
          rowData={rowData ?? []}
          columnDefinition={columnDefinition}
          rowCount={rowCount ?? 0}
          pagination={pagination}
          setPagination={setPagination}
          isLoading={isFetchingProjectListData}
          handleRowClick={rowClickHandler}
          isErrorFetchingData={isErrorFetchingProjectListData}
        />
      )}
    </Box>
  )
}

export default ProjectList
