import { ErrorOutline } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { isValidBIC, isValidIBAN } from "ibantools"
import { Controller, useFormContext } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/shared/components"
import { normalizeWhitespace } from "src/shared/functions"
import { useI18nCountries } from "src/shared/i18n"

const AccountDataForm = () => {
  const { t } = useTranslation("translation")
  const { possibleCountries } = useI18nCountries()

  const { control, watch, unregister } = useFormContext()

  const bankAccountType = watch("bankAccount.type")

  const accountFormData = {
    sepaType: { inputName: "bankAccount.type" },
    iban: {
      inputName: "bankAccount.iban",
      placeholder: t("common.ibanPlaceholder"),
      labelName: t("common.iban")
    },
    bic: {
      inputName: "bankAccount.bic",
      placeholder: t("common.bicPlaceholder"),
      labelName: t("common.bic")
    },
    accountHolder: {
      inputName: "bankAccount.accountHolder",
      labelName: t("common.accountHolder")
    },
    holderAddress: {
      street: {
        inputName: "bankAccount.holderAddress.street",
        placeholder: t("common.streetPlaceholder"),
        labelName: t("common.street"),
        rules: {
          required: t("common.streetRequiredErrorMessage")
        }
      },
      houseNumber: {
        inputName: "bankAccount.holderAddress.houseNumber",
        placeholder: t("common.houseNumberPlaceholder"),
        labelName: t("common.houseNumber")
      },
      zip: {
        inputName: "bankAccount.holderAddress.zip",
        placeholder: t("common.zipPlaceholder"),
        labelName: t("common.zip"),
        rules: {
          required: t("common.zipRequiredErrorMessage")
        }
      },
      city: {
        inputName: "bankAccount.holderAddress.city",
        placeholder: t("common.cityPlaceholder"),
        labelName: t("common.city"),
        rules: {
          required: t("common.cityRequiredErrorMessage")
        }
      },
      country: {
        inputName: "bankAccount.holderAddress.country",
        labelName: t("common.country"),
        selectItems: [
          {
            text: t("common.defaultSelect"),
            value: ""
          },
          ...possibleCountries()
        ],
        rules: {
          required: t("common.countryRequiredErrorMessage")
        }
      }
    },
    accountNumber: {
      inputName: "bankAccount.accountNumber",
      placeholder: t("common.accountNumberPlaceholder"),
      labelName: t("common.accountNumber"),
      rules: {
        required: t("common.accountNumberRequiredErrorMessage")
      }
    },
    bankAddress: {
      bankName: {
        inputName: "bankAccount.bankAddress.name",
        placeholder: t("common.bankNamePlaceholder"),
        labelName: t("common.bankName"),
        rules: {
          required: t("common.bankNameRequiredErrorMessage")
        }
      },
      street: {
        inputName: "bankAccount.bankAddress.street",
        placeholder: t("common.streetPlaceholder"),
        labelName: t("common.street"),
        rules: {
          required: t("common.streetRequiredErrorMessage")
        }
      },
      houseNumber: {
        inputName: "bankAccount.bankAddress.houseNumber",
        placeholder: t("common.houseNumberPlaceholder"),
        labelName: t("common.houseNumber")
      },
      zip: {
        inputName: "bankAccount.bankAddress.zip",
        placeholder: t("common.zipPlaceholder"),
        labelName: t("common.zip"),
        rules: {
          required: t("common.zipRequiredErrorMessage")
        }
      },
      city: {
        inputName: "bankAccount.bankAddress.city",
        placeholder: t("common.cityPlaceholder"),
        labelName: t("common.city"),
        rules: {
          required: t("common.cityRequiredErrorMessage")
        }
      },
      country: {
        inputName: "bankAccount.bankAddress.country",
        labelName: t("common.country"),
        selectItems: [
          {
            text: t("common.defaultSelect"),
            value: ""
          },
          ...possibleCountries()
        ],
        rules: {
          required: t("common.countryRequiredErrorMessage")
        }
      }
    }
  }

  return (
    <Box data-testid="onboardingAccountDataForm">
      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom data-testid="accountInfoHeader">
            {t("onboardingPage.accountFormData.pageSubtitle.title")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={accountFormData.sepaType.inputName}
                  render={({ field: { onChange, value } }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              if (value === "SEPA") {
                                onChange("NON_SEPA")
                                unregister(accountFormData.iban.inputName)
                              } else {
                                onChange("SEPA")
                              }
                            }}
                            checked={value === "SEPA" ? false : true}
                          />
                        }
                        data-testid="bankAccountTypeCheckbox"
                        label={
                          <Trans
                            i18nKey="onboardingPage.accountFormData.bankAccountTypeLabel"
                            components={{ strong: <strong /> }}
                          />
                        }
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="McpInfoText">
                  <ErrorOutline fontSize="small" />
                  {t("onboardingPage.accountFormData.bankAccountTypeInfoText")}
                </Box>
              </Grid>
            </Grid>

            <Grid item data-testid="accountHolderInputGroup" xs={12} md={12}>
              <FormInputGroup
                disabled={true}
                inputName={accountFormData.accountHolder.inputName}
                labelName={accountFormData.accountHolder.labelName}
              />
            </Grid>

            <Grid item data-testid="bicInputGroup" xs={12} md={6}>
              <FormInputGroup
                placeholder={accountFormData.bic.placeholder}
                inputName={accountFormData.bic.inputName}
                labelName={accountFormData.bic.labelName}
                isLabelRequired={true}
                onInput={(value) => normalizeWhitespace(value).toUpperCase()}
                rules={{
                  required: t("common.bicRequiredErrorMessage"),
                  validate: (value: string) =>
                    isValidBIC(value) || t("common.invalidBicErrorMessage")
                }}
              />
            </Grid>

            <Grid item data-testid="ibanInputGroup" xs={12} md={6}>
              <FormInputGroup
                placeholder={accountFormData.iban.placeholder}
                inputName={accountFormData.iban.inputName}
                labelName={accountFormData.iban.labelName}
                isLabelRequired={bankAccountType === "SEPA" ? true : false}
                onInput={(value) => normalizeWhitespace(value).toUpperCase()}
                rules={
                  bankAccountType === "SEPA"
                    ? {
                        required: t("common.ibanRequiredErrorMessage"),
                        validate: (value: string) =>
                          isValidIBAN(value) ||
                          t("common.invalidIbanErrorMessage")
                      }
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {bankAccountType && bankAccountType !== "SEPA" && (
        <Box className="McpBox">
          <Grid item className="McpBoxHeader">
            <Typography variant="h2" data-testid="noIbanAccountInfoHeader">
              {t("onboardingPage.accountFormData.pageSubtitle.noIbanTitle")}
            </Typography>
          </Grid>
          <Container>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid container item xs={12} md={6} spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      borderBottom: "none !important",
                      paddingLeft: "0 !important",
                      paddingBottom: "0 !important"
                    }}
                  >
                    {t("common.beneficiarySubtitle")}
                  </Typography>{" "}
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormInputGroup
                    placeholder={
                      accountFormData.holderAddress.street.placeholder
                    }
                    inputName={accountFormData.holderAddress.street.inputName}
                    labelName={accountFormData.holderAddress.street.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.holderAddress.street.rules}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormInputGroup
                    placeholder={
                      accountFormData.holderAddress.houseNumber.placeholder
                    }
                    inputName={
                      accountFormData.holderAddress.houseNumber.inputName
                    }
                    labelName={
                      accountFormData.holderAddress.houseNumber.labelName
                    }
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormInputGroup
                    placeholder={accountFormData.holderAddress.zip.placeholder}
                    inputName={accountFormData.holderAddress.zip.inputName}
                    labelName={accountFormData.holderAddress.zip.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.holderAddress.zip.rules}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormInputGroup
                    placeholder={accountFormData.holderAddress.city.placeholder}
                    inputName={accountFormData.holderAddress.city.inputName}
                    labelName={accountFormData.holderAddress.city.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.holderAddress.city.rules}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormSelectGroup
                    inputName={accountFormData.holderAddress.country.inputName}
                    labelName={accountFormData.holderAddress.country.labelName}
                    isLabelRequired={true}
                    selectItems={
                      accountFormData.holderAddress.country.selectItems
                    }
                    rules={accountFormData.holderAddress.country.rules}
                    countryFlags={true}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={6} spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      borderBottom: "none !important",
                      paddingLeft: "0 !important",
                      paddingBottom: "0 !important"
                    }}
                  >
                    {t("common.bankDetailsSubtitle")}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormInputGroup
                    placeholder={
                      accountFormData.bankAddress.bankName.placeholder
                    }
                    inputName={accountFormData.bankAddress.bankName.inputName}
                    labelName={accountFormData.bankAddress.bankName.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.bankAddress.bankName.rules}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormInputGroup
                    placeholder={accountFormData.accountNumber.placeholder}
                    inputName={accountFormData.accountNumber.inputName}
                    labelName={accountFormData.accountNumber.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.accountNumber.rules}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormInputGroup
                    placeholder={accountFormData.bankAddress.street.placeholder}
                    inputName={accountFormData.bankAddress.street.inputName}
                    labelName={accountFormData.bankAddress.street.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.bankAddress.street.rules}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormInputGroup
                    placeholder={
                      accountFormData.bankAddress.houseNumber.placeholder
                    }
                    inputName={
                      accountFormData.bankAddress.houseNumber.inputName
                    }
                    labelName={
                      accountFormData.bankAddress.houseNumber.labelName
                    }
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormInputGroup
                    placeholder={accountFormData.bankAddress.zip.placeholder}
                    inputName={accountFormData.bankAddress.zip.inputName}
                    labelName={accountFormData.bankAddress.zip.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.bankAddress.zip.rules}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormInputGroup
                    placeholder={accountFormData.bankAddress.city.placeholder}
                    inputName={accountFormData.bankAddress.city.inputName}
                    labelName={accountFormData.bankAddress.city.labelName}
                    isLabelRequired={true}
                    rules={accountFormData.bankAddress.city.rules}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormSelectGroup
                    inputName={accountFormData.bankAddress.country.inputName}
                    labelName={accountFormData.bankAddress.country.labelName}
                    isLabelRequired={true}
                    selectItems={
                      accountFormData.bankAddress.country.selectItems
                    }
                    rules={accountFormData.bankAddress.country.rules}
                    countryFlags={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  )
}

export default AccountDataForm
