import { atomWithReset } from "jotai/utils"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import {
  ApiError,
  get as getClient,
  post as postClient
} from "src/shared/client"

import { projectIdAtom } from "./projectDataStore"

type PostProjectStatusChangeRequest = Pick<
  ProjectStatusChangeRecord,
  "comment" | "status"
>

export const projectCommentsCountAtom = atomWithReset<number>(0)

export const projectStatusChangeListAtom = atomWithQuery<
  ProjectStatusChangeList,
  ApiError
>((get) => {
  const projectId = get(projectIdAtom)

  return {
    queryKey: ["projectComments", projectId],
    queryFn: async () =>
      await (
        await getClient({
          path: `/project-service/projects/${projectId}/states`
        })
      ).json(),
    enabled: !!projectId
  }
})

export const postProjectStatusChangeAtom = atomWithMutation<
  unknown,
  PostProjectStatusChangeRequest,
  ApiError
>((get) => {
  const projectId = get(projectIdAtom)

  return {
    mutationFn: async (statusChangeRequest) =>
      await postClient({
        path: `/project-service/projects/${projectId}/states`,
        body: statusChangeRequest
      })
  }
})
