import { Box, Container, Grid } from "@mui/material"
import { type ReactNode } from "react"

const DetailsDrawer = ({
  testId,
  children
}: {
  testId: string
  children: ReactNode
}) => (
  <Container data-testid={testId} maxWidth={false}>
    <Box className="McpDrawerBox">
      <Grid container className="McpDrawerContent">
        {children}
      </Grid>
    </Box>
  </Container>
)

export default DetailsDrawer
