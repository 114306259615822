import { Person2 } from "@mui/icons-material"
import { InfoOutlined } from "@mui/icons-material"
import { Alert, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingContainer } from "src/shared/components"
import { handleDate, handleTime } from "src/shared/functions"

const ProjectCommentListView = ({
  projectData,
  projectStatusAndCommentData
}: {
  projectData: ProjectRecord
  projectStatusAndCommentData: ProjectStatusChangeList
}) => {
  const { t } = useTranslation("translation")

  if (!projectStatusAndCommentData) {
    return <LoadingContainer />
  }

  const commentRecordsList = projectStatusAndCommentData.changelog.filter(
    (record) => record.comment
  )

  return (
    <Grid
      container
      item
      data-testid={"commentListView"}
      className="McpComments"
      rowSpacing={2}
    >
      {commentRecordsList.length === 0 ? (
        <Grid item xs={12} data-testid="noCommentsAlert">
          <Alert severity="info">
            {t("common.commentsTab.noCommentsAlert")}
          </Alert>
        </Grid>
      ) : (
        commentRecordsList.map((commentRecord) => (
          <Grid
            container
            item
            key={commentRecord.projectStatusChangeId}
            xs={12}
          >
            <Grid item>
              <Person2 />
            </Grid>
            <Grid item>
              <Typography variant="caption" data-testid="projectComment">
                {`${commentRecord.author} | ${handleDate(commentRecord.createdAt)}, ${handleTime(commentRecord.createdAt)}`}
              </Typography>

              <Typography
                variant="body1"
                whiteSpace={"pre-wrap"}
              >{` ${commentRecord.comment}`}</Typography>
            </Grid>
          </Grid>
        ))
      )}

      {projectData.status !== "WAITING_FOR_MERCHANT" && (
        <Grid
          item
          xs={12}
          className="McpInfoText"
          data-testid="McpNoCommentPossible"
        >
          <InfoOutlined fontSize="small" />
          <Typography variant="caption">
            {t("projectDetails.commentsTab.noCommentsInStatusAllowed")}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default ProjectCommentListView
