import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { Controller, useFormContext } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { UploadedDocumentsList } from "src/features/onboarding"
import { Dropzone } from "src/shared/components"
import {
  onboardingDocumentDataAtom,
  uploadOnboardingDocumentDataAtom
} from "src/shared/stores"

const DocumentsListForm = () => {
  const { t } = useTranslation("translation")

  const { enqueueSnackbar } = useSnackbar()
  const { control } = useFormContext()

  const [
    {
      isLoading: isLoadingDocumentsList,
      refetch: refetchUploadedOnboardingDocuments,
      isError: isOnboardingDocumentsFetchError
    }
  ] = useAtom(onboardingDocumentDataAtom)
  const [
    {
      mutateAsync: postNewOnboardingDocument,
      isPending: isUploadingNewOnboardingDocument
    }
  ] = useAtom(uploadOnboardingDocumentDataAtom)

  const handleFileUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles) {
      const files = [...acceptedFiles]
      const uploadPromises = files.map((newFile: File) =>
        postNewOnboardingDocument(newFile)
      )

      Promise.allSettled(uploadPromises).then((results) => {
        results.every((result) => {
          if (result.status === "fulfilled") {
            enqueueSnackbar({
              variant: "success",
              message: t(
                "onboardingPage.documentsFormData.documentUploadSuccess",
                {
                  fileName: result.value.fileName
                }
              )
            })

            return true
          } else if (result.status === "rejected") {
            return handleError(result)
          }
        })

        if (results.find((item) => item.status !== "rejected")) {
          refetchUploadedOnboardingDocuments()
        }
      })
    }

    document.querySelector<HTMLButtonElement>("#nextButton")?.focus()
  }

  const handleError = (errorResult: PromiseRejectedResult) => {
    const resultData = errorResult.reason.response.data
    const mediaType = resultData.message
      .substring(resultData.message.indexOf(":") + 1)
      .trim()

    switch (true) {
      case resultData.message.startsWith("Quota limit reached"):
        enqueueSnackbar({
          message: t(
            "onboardingPage.documentsFormData.documentUploadLimitReachedFailure"
          ),
          variant: "error",
          autoHideDuration: null
        })
        return false

      case resultData.message.startsWith("Request Entity Too Large"):
        enqueueSnackbar({
          message: t(
            "onboardingPage.documentsFormData.documentUploadFileSizeFailure"
          ),
          variant: "error",
          autoHideDuration: null,
          preventDuplicate: false
        })
        return true

      default:
        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t(resultData.code, {
            keyPrefix: "errorCodes",
            fileExtension: mediaType
          }),
          details: resultData.message,
          autoHideDuration: null,
          preventDuplicate: false
        })

        return true
    }
  }

  return (
    <Box>
      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("onboardingPage.documentsFormData.displayName")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2} data-testid="onboardingDocumentsListForm">
            <Grid item xs={12}>
              <Typography variant="body1" data-testid="documentsInfoHeader">
                <Trans
                  i18nKey="onboardingPage.documentsFormData.outerDropzoneText"
                  components={{ 1: <br />, 2: <b /> }}
                ></Trans>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="isExemptFromTaxFiling"
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={onChange} checked={value} />}
                      data-testid="isExemptFromTaxFilingCheckbox"
                      label={t(
                        "onboardingPage.documentsFormData.noTaxAssessedCheckboxLabel"
                      )}
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Dropzone
                handleFileUpload={handleFileUpload}
                isUploading={isUploadingNewOnboardingDocument}
              ></Dropzone>
            </Grid>

            <Grid item xs={12}>
              <Alert severity={"info"}>
                <Box>
                  <Typography>
                    {t("onboardingPage.documentsFormData.fileRestrictions")}
                  </Typography>
                </Box>
              </Alert>
            </Grid>

            <Grid item xs={12}>
              {isLoadingDocumentsList ? (
                <Grid className="Test" item container xs={12}>
                  <CircularProgress data-testid="documentsListSpinner" />
                </Grid>
              ) : isOnboardingDocumentsFetchError ? (
                <Grid className="Test2" item container xs={12}>
                  <Alert
                    data-testid="onboardingDocumentsFetchError"
                    severity="error"
                  >
                    {t(
                      "onboardingPage.documentsFormData.documentListFetchFailure"
                    )}
                  </Alert>
                </Grid>
              ) : (
                <Grid item>
                  <UploadedDocumentsList />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default DocumentsListForm
