import { Grid } from "@mui/material"
import { type ReactElement } from "react"

import SummaryItem from "./SummaryItem"

const DepartmentSummaryItem = ({
  labelEmail,
  email,
  labelPhone,
  phone,
  iconPhone,
  iconEmail
}: {
  labelEmail: string
  email?: string
  labelPhone: string
  phone?: string
  iconPhone?: ReactElement
  iconEmail?: ReactElement
}) => (
  <>
    <Grid item xs={12} lg={6}>
      <SummaryItem icon={iconPhone} labelName={labelPhone} itemName={phone} />
    </Grid>

    <Grid item xs={12} lg={6}>
      <SummaryItem icon={iconEmail} labelName={labelEmail} itemName={email} />
    </Grid>
  </>
)

export default DepartmentSummaryItem
