import { Box, createTheme, ThemeProvider } from "@mui/material"
import { deDE as coreDE, enUS as coreEN } from "@mui/material/locale"
import type {
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridPaginationModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowParams,
  GridValidRowModel
} from "@mui/x-data-grid"
import { DataGrid } from "@mui/x-data-grid"
import { deDE, enUS } from "@mui/x-data-grid/locales"
import { deDE as pickerDE, enUS as pickerEN } from "@mui/x-date-pickers/locales"
import { type JSX, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CustomErrorOverlay } from "src/shared/components"
import { theme } from "src/shared/theming"

type PaginatedDataTableProps = {
  id?: string
  shouldBeDisabled?: boolean
  filterModel?: GridFilterModel
  rowData: GridValidRowModel[]
  columnDefinition: GridColDef[]
  rowCount: number
  pagination?: GridPaginationModel
  setPagination: (pagination: GridPaginationModel) => void
  isLoading: boolean
  handleRowClick?: GridEventListener<"rowClick">
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue
  isErrorFetchingData: boolean
  customOverlay?: {
    customOverlay: () => JSX.Element
    overlayCondition: boolean
  }
}

const PaginatedDataTable = ({
  id,
  shouldBeDisabled,
  filterModel,
  rowData,
  columnDefinition,
  rowCount,
  pagination,
  setPagination,
  isLoading,
  handleRowClick,
  getRowHeight,
  isErrorFetchingData,
  customOverlay
}: PaginatedDataTableProps) => {
  const { i18n } = useTranslation()

  const handleDataGridLocales = useMemo(
    () => (lng: string) => {
      switch (lng) {
        default:
          return [deDE, pickerDE, coreDE]
        case "en":
          return [enUS, pickerEN, coreEN]
      }
    },
    []
  )

  const themeWithLocales = useMemo(
    () => createTheme(theme, ...handleDataGridLocales(i18n.language)),
    [handleDataGridLocales, i18n.language]
  )

  const paginationSettings: TablePaginationSettings = useMemo(
    () => ({
      pageSizeOptions: [10, 25, 50, 100],
      paginationMode: "server"
    }),
    []
  )

  const defaultPagination = {
    pageSize: 10,
    page: 0
  }

  const handleRowClassName = useMemo(
    () => (params: GridRowParams) => (id && params.id === id ? "active" : ""),
    [id]
  )

  return (
    <Box>
      <ThemeProvider theme={themeWithLocales}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center"
            },
            "& .MuiDataGrid-row:hover": shouldBeDisabled
              ? {
                  backgroundColor: "transparent !important",
                  cursor: "default"
                }
              : {}
          }}
          data-testid="paginatedDataTable"
          getRowHeight={getRowHeight}
          loading={isLoading}
          filterModel={filterModel}
          rows={rowData}
          columns={columnDefinition}
          rowCount={rowCount}
          pageSizeOptions={paginationSettings.pageSizeOptions}
          paginationModel={pagination ?? defaultPagination}
          paginationMode={paginationSettings.paginationMode}
          onPaginationModelChange={setPagination}
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          resizeThrottleMs={0}
          getRowClassName={handleRowClassName}
          slots={{
            noRowsOverlay: customOverlay?.overlayCondition
              ? customOverlay.customOverlay
              : isErrorFetchingData
                ? () => <CustomErrorOverlay />
                : undefined
          }}
        />
      </ThemeProvider>
    </Box>
  )
}

export default PaginatedDataTable
