import { Box, Grid, Typography } from "@mui/material"
import { type ReactElement } from "react"

const SummaryItem = ({
  itemName,
  labelName,
  inputName,
  icon,
  countryFlags
}: {
  itemName?: string
  labelName?: string
  inputName?: string
  icon?: ReactElement
  countryFlags?: string
}) => (
  <Grid item xs={12} md={12} className="summaryItem">
    <Typography variant="subtitle2" gutterBottom>
      {labelName}
    </Typography>
    <Typography
      variant="body1"
      gutterBottom
      data-testid={inputName ? inputName : `${inputName}_empty`}
      className="summaryValue"
    >
      {countryFlags && (
        <Box
          component="span"
          className={`fi fi-${countryFlags ? countryFlags.toLowerCase() : ""} McpFlag`}
        />
      )}
      {icon && <span className="summaryIcon">{icon}</span>} {itemName || "~"}
    </Typography>
  </Grid>
)

export default SummaryItem
