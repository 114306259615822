import { KeyboardArrowRight } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { CreateProjectDialog } from "src/features/project"
import { useDrawerFunctions } from "src/shared/functions"
import {
  isDrawerOpenAtom,
  merchantDataAtom,
  projectIdAtom
} from "src/shared/stores"
import ProjectDetails from "src/widgets/projectDetails"
import ProjectList from "src/widgets/projectList"

const ProjectsPage = () => {
  const { t } = useTranslation("translation")
  const { projectId } = useParams()

  const isDrawerOpen = useAtomValue(isDrawerOpenAtom)
  const setProjectId = useSetAtom(projectIdAtom)
  const [{ data: merchantData }] = useAtom(merchantDataAtom)

  const { handleDrawerOpen } = useDrawerFunctions()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleDialogOpen = useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  const toggleIsDialogOpen = useCallback(() => {
    setIsDialogOpen(!isDialogOpen)
  }, [isDialogOpen])

  useEffect(() => {
    if (projectId) {
      setProjectId(projectId)
      handleDrawerOpen()
    } else {
      setProjectId(undefined)
    }
  }, [handleDrawerOpen, projectId, setProjectId])

  return (
    <Grid
      container
      className={`${isDrawerOpen ? "McpDrawerOpen" : ""} McpContentGrid`}
    >
      <Grid className="McpContentView" data-testid="projectsPage">
        {/* STICKY */}
        <Box className={"McpContentViewSticky"}>
          <Grid container item alignItems={"center"}>
            <Grid item xs="auto" className="McpContentViewStickyHeadline">
              <Typography variant="h2">
                {t("projectPage.pageHeadline")}
              </Typography>
            </Grid>
            {merchantData?.status === "ACTIVE" && (
              <Grid item xs="auto" ml="auto">
                <Button
                  data-testid="newProjectButton"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleDialogOpen}
                  endIcon={<KeyboardArrowRight />}
                >
                  {t("projectPage.newProjectButton")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* CONTENT */}
        {/* PROJECT TABLE */}
        <Box data-testid="projectTable">
          <ProjectList />
        </Box>
      </Grid>

      {/* DETAILS DRAWER */}
      {isDrawerOpen && (
        <Grid className="McpDrawer">
          <ProjectDetails />
        </Grid>
      )}

      <CreateProjectDialog
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={toggleIsDialogOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
    </Grid>
  )
}

export default ProjectsPage
